import * as React from 'react';
import { PageProps, graphql } from 'gatsby';
import { AllImage, GatsbyImageFileWithName, PageContextProps } from '../../utils/graphql';
import Index from '../../components/academy/masterclass/Index';

export const query = graphql`
  query {
    masterImage: file(
      sourceInstanceName: { eq: "images" }
      relativeDirectory: { eq: "masterClass" }
      name: { eq: "arie-van-bennekum" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 400, placeholder: BLURRED)
      }
    }
    ogImage: file(
      sourceInstanceName: { eq: "images" }
      relativeDirectory: { eq: "masterClass" }
      name: { eq: "arie-van-bennekum" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    pictures: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "masterClass/pictures/arie-van-bennekum" }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 584, placeholder: BLURRED)
        }
      }
    }
  }
`;

type ArieVanBennekumBusinessAgilityMasterclassQueryProps = {
  masterImage: GatsbyImageFileWithName;
  ogImage: GatsbyImageFileWithName;
  pictures: AllImage;
};

type ArieVanBennekumBusinessAgilityMasterclassProps = PageProps<
  ArieVanBennekumBusinessAgilityMasterclassQueryProps,
  PageContextProps
>;

const ArieVanBennekumBusinessAgilityMasterclass: React.VoidFunctionComponent<
  ArieVanBennekumBusinessAgilityMasterclassProps
> = ({
  data: {
    masterImage,
    ogImage,
    pictures: { nodes: pictures },
  },
  pageContext,
}) => {
  return (
    <Index
      masterclass="arie-van-bennekum"
      masterImage={masterImage}
      ogImage={ogImage}
      pictures={pictures}
      programFile="TRAINING_PROGRAM_arie_van_bennekum.pdf"
      locale={pageContext.locale}
    />
  );
};

export default ArieVanBennekumBusinessAgilityMasterclass;
